<template>
  <div>
     <component :is="componentId" />
  </div>
</template>

<script type="text/babel">

export default {
  name: "LeaseConfigurationTabView",
  components: {
    'corporate-lease-template': () => import('./../Templates'),
    'property-lease-template': () => import('./Index.vue'),
  },
  props: ["level"],
  computed: {
    componentId() {
      let template = "lease-template";
      return this.level === "Corporate" ? `corporate-${template}` : `property-${template}`;
    }
  }
};
</script>
